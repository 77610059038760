import Modal from 'react-bootstrap/Modal';

function AccountSuspensionModal({ show, closeModal }) {
  return (
    <Modal show={show} onHide={closeModal} className="account-suspension-modal">
      <Modal.Header closeButton>
        <h4>Account Temporarily Disabled</h4>
      </Modal.Header>
      <Modal.Body>
        <p>
          Sorry but your account has been temporarily disabled for possible{' '}
          <a href="/terms" target="_blank">
            Terms of service
          </a>{' '}
          violation. Don't worry your data is safe and nothing has been deleted.
          Please{' '}
          <a href="/contact" target="_blank">
            contact us
          </a>{' '}
          for re-activation, thank you.
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default AccountSuspensionModal;
