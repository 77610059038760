import fetchData from '../../fetchData';
import setData from '../../setData';
import {
  CreateProduct,
  SingleProductJsonByIdQuery,
} from '../createproduct/queries';

export const createproduct = async (variables = {}) => {
  const data = await setData(CreateProduct, { data: variables });

  return data;
};

export const getProductJsonDetailById = async (variables = {}) => {
  const data = await fetchData(SingleProductJsonByIdQuery, {
    variables,
  });

  return data.data.products_by_id;
};
