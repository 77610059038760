export const SaveProduct = `
    #graphql
    mutation savedesign($data: create_saveddesigns_input!) {
        create_saveddesigns_item(data: $data) {
          id
        }
    }
`;

export const ProJson = `
    #graphql
    query ProJson($product_slug: String) {
      products(filter: {slug: {_eq: $product_slug}}) {
          DesignJSON
      }
  }
`;

export const ProductIdByProject = `
    #graphql
    query ProductIdByProject ($designid: String!){
      saveddesigns(filter: {id: {_eq: $designid} })
      {
        product
        {
          id
        }
      }
    }
`;

export const ValidateProject = `
    #graphql
    query ValidateprojectProject ($projectid: String!, $userid: String){
      saveddesigns(filter: { id: {_eq: $projectid}, users: {id: {_eq: $userid} }})
      {
          id
      }
    }
`;

export const GetProjectsByUser = `
    #graphql
    query GetProjectsByUser ($userid: String){
      saveddesigns(filter: { users: {id: {_eq: $userid} }})
      {
          id
      }
    }
`;
