import fetchData from '../../fetchData';
import setData from '../../setData';
import {
  SaveProduct,
  ProJson,
  ProductIdByProject,
  ValidateProject,
  GetProjectsByUser,
} from '../saveproduct/queries';

export const savedesign = async (variables = {}) => {
  const data = await setData(SaveProduct, { data: variables });

  return data;
};

export const getprojson = async (variables = {}) => {
  const data = await fetchData(ProJson, {
    variables,
  });

  return data.data.products[0];
};

export const getproductidbyproject = async (variables = {}) => {
  const data = await fetchData(ProductIdByProject, {
    variables,
  });

  return data.data.saveddesigns[0].product;
};

export const validateproject = async (variables = {}) => {
  const data = await fetchData(ValidateProject, {
    variables,
  });

  return data.data;
};

export const getprojectsbyuser = async (variables = {}) => {
  const data = await fetchData(GetProjectsByUser, {
    variables,
  });

  return data.data;
};
