import fetchData from '../../fetchData';
import { GetLines, GetCategoryCount, GetSources } from './queries';

export const getLines = async (variables = {}) => {
  const data = await fetchData(GetLines, {
    variables: {},
  });

  return data.data;
};

export const getCategoryCount = async (variables = {}) => {
  const data = await fetchData(GetCategoryCount, {
    variables,
  });

  return data.data;
};

export const getSources = async (variables = {}) => {
  const data = await fetchData(GetSources, {
    variables: {},
  });

  return data.data;
};
