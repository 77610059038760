// contexts/CategoryContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

import ApiService from '../services/ApiService';
const CategoryContext = createContext();

export const CategoryProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {

      try {
        const currentDomain = window.location.hostname;
        //  const result = getInitialCategories();
        const categories = await ApiService.instance.get(
          '/api/datasource/category/getcategories',
        );

        setCategories(categories.data.Product_categories);
      } catch (err) {
        console.error('Error fetching categories:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return (
    <CategoryContext.Provider value={{ categories, loading }}>
      {children}
    </CategoryContext.Provider>
  );
};

// Custom hook to access the category context
export const useCategory = () => useContext(CategoryContext);
