import { createContext, useContext, useState } from 'react';

const ProductSlugContext = createContext();

export function useProSlug() {
  return useContext(ProductSlugContext);
}

export function ProductSlugProvider({ children }) {
  const [proslug, setProSlug] = useState('');

  const value = {
    proslug,
    setProSlug,
  };

  return (
    <ProductSlugContext.Provider value={value}>
      {children}
    </ProductSlugContext.Provider>
  );
}
