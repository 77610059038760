import fetchData from '../../fetchData';
import { CategoriesQuery, GetCategories } from './queries';

export const getCategories = async (domain) => {
  const data = await fetchData(CategoriesQuery, {
    variables: { domain },
  });

  return data.data;
};

export const getCats = async (domain) => {
  const data = await fetchData(GetCategories, {
    variables: { domain },
  });

  return data.data;
};
