import axios from 'axios';

class ApiClient {
  constructor() {
    if (!ApiClient.instance) {
      this.timeout = 30000;
      this.instance = axios.create({
        timeout: this.timeout,
        withCredentials: true,
      });

      // // Add a request interceptor
      // this.instance.interceptors.request.use(
      //   async (config) => {
      //     try {
      //       return config;
      //     } catch (error) {
      //       return Promise.reject(error);
      //     }
      //   },
      //   (error) => {
      //     // Handle request error
      //     return Promise.reject(error);
      //   },
      // );

      // Store the instance
      ApiClient.instance = this;
    }

    return ApiClient.instance;
  }
}

const ApiService = new ApiClient();

export default ApiService;
