import fetchData from '../../fetchData';
import {
  MyProjects,
  MyCreatedProducts,
  MyHomeProjects,
  MyProject,
} from './queries';

export const getMyProjects = async (variables = {}) => {
  const data = await fetchData(MyProjects, {
    variables,
  });

  return data.data;
};

export const getMyCreatedProducts = async (variables = {}) => {
  const data = await fetchData(MyCreatedProducts, {
    variables,
  });

  return data.data;
};

export const getMyHomeProjects = async (variables = {}) => {
  const data = await fetchData(MyHomeProjects, {
    variables,
  });

  return data.data;
};

export const getMyProject = async (variables = {}) => {
  const data = await fetchData(MyProject, {
    variables,
  });

  return data.data;
};
