const LoginCredentials = ({
  submitLogin,
  handleResetPasswordClick,
  handleSignUpClick,
  loading,
}) => {
  return (
    <>
      <form onSubmit={(e) => submitLogin(e)}>
        <div className="mb-3">
          <label>Email</label>
          <input type="email" name="email" className="form-control" required />
        </div>
        <div className="mb-3">
          <label>Password</label>
          <input
            type="password"
            name="password"
            className="form-control"
            required
          />
        </div>
        <div className="d-grid mb-2">
          <button disabled={loading} type="submit" className="button-outlined">
            <span>Log In</span>
          </button>
        </div>
        <p className="forgot-password text-right">
          Don&apos;t have an account?{' '}
          <button
            className="purple-button"
            variant="primary"
            onClick={() => handleSignUpClick()}
          >
            Sign Up
          </button>
        </p>
        <p className="forgot-password text-right">
          Forgot Password?{' '}
          <button
            className="purple-button"
            variant="primary"
            onClick={() => handleResetPasswordClick()}
          >
            Reset Password
          </button>
        </p>
      </form>
    </>
  );
};
export default LoginCredentials;
