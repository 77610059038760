// SiteInfoContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';
import SitesService from 'services/SitesService';

// Create the context
const SiteInfoContext = createContext();

// Custom hook to use the context
export const useSiteInfo = () => useContext(SiteInfoContext);

// Context Provider component
export const SiteInfoProvider = ({ children, initialSiteInfo }) => {
  // Initial state
  const [siteInfo, setSiteInfo] = useState(initialSiteInfo);

  useEffect(() => {
    if (!siteInfo) {
      const fetchSiteInfo = async () => {
        try {
          const domain = window.location.host;
          const { siteInfo } = await SitesService.getSiteInfo(domain);

          setSiteInfo(siteInfo);
        } catch (error) {
          console.error('Failed to fetch site info:', error);
        }
      };

      fetchSiteInfo();
    }
  }, [siteInfo]);

  // Function to update site info
  const updateSiteInfo = (newSiteInfo) => {
    setSiteInfo({ ...siteInfo, ...newSiteInfo });
  };

  return (
    <SiteInfoContext.Provider value={{ siteInfo, updateSiteInfo }}>
      {children}
    </SiteInfoContext.Provider>
  );
};
