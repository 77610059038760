function CloseChatIcon() {
  return (
    <>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 800 800">
        <g>
          <line className="st0" x1="30.3" y1="754.7" x2="768.7" y2="44.4" />
          <line className="st0" x1="30.3" y1="45.3" x2="769.7" y2="754.7" />
        </g>
      </svg>
    </>
  );
}

export default CloseChatIcon;
