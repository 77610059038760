// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import LoginModal from '@/components/auth/Login';
import RegisterModal from '@/components/auth/Register';
import Router from 'next/router';

import Image from 'next/image';
import closebtn from '@/images/closebtn.svg';
import { Modal } from 'react-bootstrap';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import { useSiteInfo } from './SiteInfoContext';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { status, data: session } = useSession();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [callback, setCallback] = useState(null);

  const { siteInfo } = useSiteInfo();

  const [showAutofillPopup, setShowAutofillPopup] = useState(false);

  const openLoginModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(true);
  };

  const openRegisterModal = () => {
    setLoginModalOpen(false);
    setRegisterModalOpen(true);
  };

  const closeModals = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
    setCallback(null);
  };

  const value = {
    openLoginModal,
    openRegisterModal,
    closeModals,
    setCallback,
    loginModalOpen,
    registerModalOpen,
  };

  const showWelcomeModal = (type) => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
    localStorage.setItem('welcome_popup', 'true');
    setShowAutofillPopup(true);
  };

  const handleWelcomeModalClose = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
    setCallback(null);
    setShowAutofillPopup(false);
    localStorage.removeItem('welcome_popup');
  };

  useEffect(() => {
    Router.events.on('routeChangeStart', (url) => {
      closeModals();
    });
  }, []);

  useEffect(() => {
    if (status === 'authenticated' && session && session.user) {
      const test = localStorage.getItem('welcome_popup');
      if (test && test === 'true') {
        showWelcomeModal();
        localStorage.removeItem('welcome_popup');
      } else handleWelcomeModalClose();
    }
  }, [status]);

  return (
    <>
      <AuthContext.Provider value={value}>
        {children}
        <LoginModal
          show={loginModalOpen}
          closeModal={closeModals}
          openRegisterModal={openRegisterModal}
          callback={callback}
          showWelcomeModal={showWelcomeModal}
        />
        <RegisterModal
          show={registerModalOpen}
          closeModal={closeModals}
          openLoginModal={openLoginModal}
          callback={callback}
        />
        <Modal
          centered
          show={showAutofillPopup}
          onHide={handleWelcomeModalClose}
          className="welcomeautofill-popup"
        >
          <span className="closebtn" onClick={handleWelcomeModalClose}>
            <Image alt="closebtn" src={closebtn} />
          </span>
          <Modal.Body>
            <>
              <h4>
                Welcome to
                <br />
                {siteInfo.site_name}!
              </h4>
              <p>
                Enter your info to auto-fill templates <br />
                or jump right into exploring
              </p>
              <div className="link-buttons">
                <Link href="/autofill">
                  <a
                    className="button-outlined"
                    onClick={() => setShowAutofillPopup(false)}
                  >
                    <span>Add Autofill</span>
                  </a>
                </Link>
                <Link href="/holidays-graphics">
                  <a
                    className="button-contained"
                    onClick={() => setShowAutofillPopup(false)}
                  >
                    Explore Templates
                  </a>
                </Link>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </AuthContext.Provider>
    </>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
