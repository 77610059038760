import Image from 'next/image';
import Link from 'next/link';
import { signIn } from 'next-auth/react';
import { useSiteInfo } from '@/context/SiteInfoContext';
const LoginShluchim = ({}) => {
  const { siteInfo } = useSiteInfo();
  return (
    siteInfo && (
      <>
        <button
          className="shluchimbtn"
          onClick={(e) => {
            e.preventDefault();
            signIn('shluchim', { callbackUrl: siteInfo.site_url });
          }}
        >
          <Image
            alt="logo"
            src="https://www.shluchim.org/img/loginwithshluchimoffice.png"
            layout="fill"
          />
        </button>
        <p className="forgot-password text-right">
          By Logging in you agree with your{' '}
          <Link href="https://koshergraphics.com/terms">
            <a target="_blank">Terms</a>
          </Link>
          .{' '}
        </p>
        <p className="forgot-password text-right">
          <strong>Canva uploading / editing is not allowed</strong>
        </p>
      </>
    )
  );
};
export default LoginShluchim;
