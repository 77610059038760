import Image from 'next/image';
import googlelogo from '@/images/googlelogo.png';
import { useSiteInfo } from '@/context/SiteInfoContext';

const LoginGoogle = ({ submitLoginWithGoogle }) => {
  const { siteInfo } = useSiteInfo();

  return (
    <>
      <button className="googlebtn" onClick={submitLoginWithGoogle}>
        <Image alt="logo" src={googlelogo} />
        Login with google
      </button>
      {siteInfo.login_method.length > 1 && (
        <span className="divider">
          <hr />
          <label>OR</label>
        </span>
      )}
    </>
  );
};
export default LoginGoogle;
