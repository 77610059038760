import fetchData from '../../fetchData';
import { CheckCoupon, GetCouponLimit } from '../coupon/queries';

export const chkcoupon = async (variables) => {
  const data = await fetchData(CheckCoupon, {
    variables,
  });

  return data;
};

export const getcouponlimit = async (variables) => {
  const data = await fetchData(GetCouponLimit, {
    variables,
  });

  return data;
};
