import setData from '../../setData';
import {
  ShareProduct,
  GetTemplate,
  ProductIdBySharedTemplate,
} from '../shareproduct/queries';
import fetchData from '../../fetchData';

export const sharetemplate = async (variables = {}) => {
  const data = await setData(ShareProduct, { data: variables });

  return data;
};

export const getTemplate = async (variables = {}) => {
  const data = await fetchData(GetTemplate, {
    variables,
  });

  return data.data;
};

export const getproductidbysharedtemplate = async (variables = {}) => {
  const data = await fetchData(ProductIdBySharedTemplate, {
    variables,
  });

  return data.data.shared_templates[0].product;
};
