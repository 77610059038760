export const CheckCoupon = `
    #graphql
    query CheckCoupon($coupon_code: String) {
      coupon_codes(filter: { coupon_code: {_eq: $coupon_code}, status: { _eq: "published" }}) {
          amount_type
          amount
          limit_per_user
          sites {
            Sites_id {
              id
            }
          }
      }
    }
`;

export const GetCouponLimit = `
    #graphql
    query GetCouponLimit($coupon_name: String,$userid: String!) {
      orders(filter: {coupon_name: {_eq: $coupon_name},users: { id: { _eq: $userid }},payment_status:{_eq: "succeeded"}}) {
          id
      }
    }
`;
