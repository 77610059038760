import { createContext, useContext, useState } from 'react';

const CloudContext = createContext();
export function usePopup() {
  return useContext(CloudContext);
}

export function CloudProvider({ children }) {
  const [cloudOpen, setCloudOpen] = useState(false);
  const openOrCloseCloud = (val) => {
    setCloudOpen(val);
  };

  const value = {
    cloudOpen,
    openOrCloseCloud,
  };

  return (
    <CloudContext.Provider value={value}>{children}</CloudContext.Provider>
  );
}
