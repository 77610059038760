import ApiService from './ApiService';

class SitesService {
  static getSources = async () => {
    try {
      const { data } = await ApiService.instance.get(
        '/api/datasource/items/sources',
      );
      return Promise.resolve(data.sources);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getSiteInfo = async (domain) => {
    try {
      const { data } = await ApiService.instance.post(
        '/api/datasource/items/site_info',
        { domain },
      );
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getAlgoliaInfo = async (domain) => {
    const { data } = await ApiService.instance.post(
      '/api/datasource/sites/get_algolia_info',
      { domain },
    );
    return data;
  };

  static getSiteCookie = async () => {
    const { data } = await ApiService.instance.get(
      '/api/middleware/set_cookie',
    );

    return data;
  };

  static getSiteAnnoucement = async (siteName) => {
    const { data } = await ApiService.instance.post(
      '/api/datasource/sites/get_announcement_info',
      { siteName },
    );

    return data;
  };
}

export default SitesService;
