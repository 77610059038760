import fetchData from '../../fetchData';
import { ValidateSlug } from '../validate/queries';

export const validateslug = async (variables) => {
  const data = await fetchData(ValidateSlug, {
    variables,
  });

  return data;
};
