const graphQLAPI = process.env.NEXT_PUBLIC_GRAPHQL;

const setData = async (mutation, data = {}, additionalPath = '') => {
  const query = JSON.stringify({
    query: mutation,
    variables: data,
  });
  const headers = {
    'content-type': 'application/json',
  };
  if (process.env.DIRECTUS_API_TOKEN) {
    headers['Authorization'] = `Bearer ${process.env.DIRECTUS_API_TOKEN}`;
  }
  const response = await fetch(`${graphQLAPI}${additionalPath}`, {
    headers,
    method: 'POST',
    body: query,
  });

  const responseJson = await response.json();
  if (responseJson.errors) {
    console.error(JSON.stringify(responseJson.errors, null, 2));
  }
  return responseJson.data;
};

export default setData;
