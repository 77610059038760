import fetchData from '../../fetchData';
import {
  GetSiteInfoById,
  GetSiteInfoByName,
  GetSiteStripeDetailsById,
  GetSiteStripeDetailsBySiteName,
  GetProStatusById,
  GetSiteFooterInfoByName,
  GetLoginInfoByName,
  GetSiteAlgoliaDetailsByName,
} from './queries';

export const getSiteInfo = async (slug) => {
  const data = await fetchData(GetSiteInfoByName, {
    variables: { slug },
  });

  return data.data.Sites[0];
};
export const getLoginInfo = async (slug) => {
  const data = await fetchData(GetLoginInfoByName, {
    variables: { slug },
  });

  return data.data.Sites[0];
};
export const getSiteFooterInfoByName = async (slug) => {
  const data = await fetchData(GetSiteFooterInfoByName, {
    variables: { slug },
  });

  return data.data.Sites[0];
};

export const getProStatusById = async (slug) => {
  const data = await fetchData(GetProStatusById, {
    variables: { slug },
  });

  return data.data.Sites[0];
};

export const getSiteInfoById = async (siteId) => {
  const data = await fetchData(GetSiteInfoById, {
    variables: {
      id: siteId,
    },
  });

  return data.data.Sites_by_id;
};

export const getSiteStripeDetailsById = async (id) => {
  const data = await fetchData(GetSiteStripeDetailsById, {
    variables: { id },
  });

  return data.data.Sites_by_id;
};

export const getSiteStripeDetailsBySiteName = async (siteName) => {
  const data = await fetchData(GetSiteStripeDetailsBySiteName, {
    variables: {
      siteName,
    },
  });

  return data.data.Sites[0];
};

export const getSiteAlgoliaDetailsByName = async (siteName) => {
  const data = await fetchData(GetSiteAlgoliaDetailsByName, {
    variables: {
      siteName,
    },
  });

  return data.data.Sites[0];
};
