import { useSiteInfo } from '@/context/SiteInfoContext';
import CloseChatIcon from 'assets/jsx-icons/CloseChatIcon';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import User from 'services/UserService';

const CheckEmailModal = ({ userData, closeModal }) => {
  const { siteInfo } = useSiteInfo();

  const resendVerificationEmail = async (onBtnClick = false) => {
    try {
      await User.sendVerificationEmail(
        userData.id,
        userData.email,
        siteInfo.site_name,
        siteInfo.site_url,
      );

      toast.success('Check your inbox to verify your email.');
    } catch (err) {
      if (err?.response?.status === 409) {
        return toast.error('Email already verified.');
      }
      toast.error('Something went wrong. Please try again.');
    } finally {
      if (onBtnClick) closeModal();
    }
  };

  useEffect(() => {
    if (userData && !userData.emailSend) resendVerificationEmail();
  }, [userData]);

  return (
    siteInfo && (
      <Modal
        centered
        show={userData ? true : false}
        onHide={closeModal}
        className="check-email-popup"
      >
        <Modal.Body>
          <>
            <button onClick={closeModal} className="close-btn">
              <CloseChatIcon />
            </button>
            <h4>Please check your email address</h4>
            <p>
              We’ve sent an email to {userData.email}. Please check your email
              folder, and click on the link to access your account
            </p>
            <div className="btn-hover-outer resend-btn">
              <button
                className="button-outlined lg"
                onClick={async () => await resendVerificationEmail(true)}
              >
                <span>Resend Email</span>
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    )
  );
};

export default CheckEmailModal;
