import fetchData from '../../fetchData';
import { AiSettingsQuery } from './queries';

export const getAiChatSettings = async () => {
  const data = await fetchData(AiSettingsQuery, {
    variables: {},
  });

  return data.data?.ai_chat || null;
};
