import fetchData from '../../fetchData';
import { GetEmailContent, GetSmtpInfo } from './queries';

export const getEmailContent = async (slug) => {
  const data = await fetchData(GetEmailContent, {
    variables: { slug },
  });

  return data.data.email_template[0];
};

export const getSmtpInfo = async (slug) => {
  const data = await fetchData(GetSmtpInfo, {
    variables: { slug },
  });

  return data.data.Sites[0];
};
