export const CategoriesQuery = `
#graphql
query Categories($domain: String) {
  Product_categories(
    filter: {
      parent: { cat_id: { _null: true } }
      site_id: { Sites_id: { domain_name: { _eq: $domain } } }
      status: { _in: ["published"] }
    }
  ) {
    category_name
    cat_id
    slug
    show_on_top_nav
    sort
    parent {
      cat_id
    }
    child(
      filter: {
        site_id: { Sites_id: { domain_name: { _eq: $domain } } }
        status: { _in: ["published"] }
      }
    ) {
      slug
      cat_id
      category_name
      parent {
        slug
      }
      site_id {
        id
      }
    }
  }
}
`;

export const GetCategories = `
#graphql
query GetCategories($domain: String) {
  Product_categories(filter: { 
    status: { _eq: "published" } 
    site_id: { Sites_id: { domain_name: { _eq: $domain } } }
    }
  ) {
    cat_id
    category_name
  }
}
`;
