import setData from '../../setData';
import fetchData from '../../fetchData';
import {
  OrderProduct,
  GetOrderForPaymentVerification,
  UpdateOrder,
  GetOrdersByStatus,
  GetOrderForPaymentVerificationUsingPaymentIntentId,
  MyOrders,
  OrderById,
} from '../orderproduct/queries';

export const orderdesign = async (variables = {}) => {
  const data = await setData(OrderProduct, { data: variables });

  return data;
};

export const createOrder = async (variables = {}) => {
  const data = await setData(OrderProduct, { data: variables });
  return data;
};

export const getOrdersByStatus = async (variables = {}) => {
  const {
    data: { orders },
  } = await fetchData(GetOrdersByStatus, { variables });
  return orders;
};

export const getOrdersForPaymentVerification = async (variables = {}) => {
  const {
    data: { orders },
  } = await fetchData(GetOrderForPaymentVerification, { variables });
  return orders;
};

export const getOrdersForPaymentVerificationUsingPaymenetIntentId = async (
  variables = {},
) => {
  const {
    data: { orders },
  } = await fetchData(GetOrderForPaymentVerificationUsingPaymentIntentId, {
    variables,
  });
  return orders;
};

export const updateOrder = async (variables = {}) => {
  const data = await setData(UpdateOrder, { ...variables });
  return data.update_orders_item;
};

export const getMyOrders = async (variables = {}) => {
  const data = await fetchData(MyOrders, {
    variables,
  });

  return data.data;
};

export const getOrderById = async (variables = {}) => {
  const data = await fetchData(OrderById, {
    variables,
  });

  return data.data.orders_by_id;
};
