export const OrderProduct = `
    #graphql
    mutation orders($data: create_orders_input!) {
        create_orders_item(data: $data) {
          id
          amount
          payment_type
          design_img
          date_created_func
          {
            day
            month
            year
          }
          product
          {
            title
          }
          users
          {
            email
          }
        }
    }
`;

export const GetOrdersByStatus = `
  #graphql
  query Orders($status: String, $userId: String, $productId: GraphQLStringOrFloat) {
    orders(filter: {
      status: { _eq: $status },
      users: {
        id: { _eq: $userId }
      },
      product: {
        id: { _eq: $productId }
      }
    }) {
      id
      amount
      payment_type
      payment_status
      download_count
      status
      product {
        id
      }
      users {
        id
      }
    }
  }
`;

export const GetOrderForPaymentVerification = `
  #graphql
  query Orders($paymentIntentId: String, $paymentType: String, $userId: String) {
    orders(filter: {
      stripe_payment_intent_id: { _eq: $paymentIntentId },
      payment_type: { _eq: $paymentType },
      users: {
        id: { _eq: $userId }
      }
    }) {
      id
      amount
      payment_type
      payment_status
      status
    }
  }
`;

export const GetOrderForPaymentVerificationUsingPaymentIntentId = `
  #graphql
  query Orders($paymentIntentId: String, $paymentType: String) {
    orders(filter: {
      stripe_payment_intent_id: { _eq: $paymentIntentId },
      payment_type: { _eq: $paymentType },
    }) {
      id
      amount
      payment_type
      payment_status
      status
      site_id {
        id
      }
      users {
        id
      }
    }
  }
`;

export const UpdateOrder = `
    #graphql
    mutation update_order($data: update_orders_input!, $id: ID!) {
      update_orders_item(data: $data, id: $id) {
        id
        download_count
        design_img
      }
    }
`;

export const MyOrders = `
    #graphql
    query MyOrders ($userid: String!){
      orders(filter: {payment_status: { _eq: "succeeded" }, users: {id: {_eq: $userid} }},limit: 1000,sort:"-date_created")
      {
        id
        amount
        product
        {
          title
        }
        saveddesign
        {
          design_name
          thumbnail
        }
        date_func
        {
          day
          month
          year
        }
        design_img
      }
    }
`;

export const OrderById = `
  #graphql
  query OrderById($id: ID!) {
    orders_by_id(id: $id) {
          id
          email_sent
          amount
          payment_type
          design_img
          date_created_func
          {
            day
            month
            year
          }
          product
          {
            title
          }
          users
          {
            email
          }
            site_id {
			     domain_name
		        }
    }
  }
`;
