import * as directusHomePageAPI from './directus/homepage';
import * as directusCategoryAPI from './directus/categories';
import * as directusSaveProductAPI from './directus/saveproduct';
import * as directusUpdateProductAPI from './directus/updateproduct';
import * as directusMyProjectsAPI from './directus/myprojects';
import * as directusSaveEnquiryAPI from './directus/saveenquiry';
import * as directusOrderProductAPI from './directus/orderproduct';
import * as directusShareProductAPI from './directus/shareproduct';
import * as directusCouponAPI from './directus/coupon';
import * as directusUserAPI from './directus/user';
import * as directusCreateProductAPI from './directus/createproduct';
import * as directusValidateAPI from './directus/validate';
import * as directusAiChatAPI from './directus/aichat';
import * as directusItemsAPI from './directus/items';
import * as directusSitesAPI from './directus/sites';
import * as directusEmailTemplateAPI from './directus/email';

const datasource = {
  ...directusHomePageAPI,
  ...directusCategoryAPI,
  ...directusSaveProductAPI,
  ...directusMyProjectsAPI,
  ...directusUpdateProductAPI,
  ...directusSaveEnquiryAPI,
  ...directusOrderProductAPI,
  ...directusShareProductAPI,
  ...directusCouponAPI,
  ...directusUserAPI,
  ...directusCreateProductAPI,
  ...directusValidateAPI,
  ...directusAiChatAPI,
  ...directusItemsAPI,
  ...directusSitesAPI,
  ...directusEmailTemplateAPI,
};

export default datasource;
