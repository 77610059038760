import ApiService from './ApiService';

export default class SavedDesigns {
  static getSavedDesignLimit = async () => {
    try {
      const { data } = await ApiService.instance.get(
        '/api/getsaveddesignslimit',
      );
      return Promise.resolve(data.data.data.Value);
    } catch (e) {
      return Promise.reject();
    }
  };

  static deleteSavedDesign = async (id) => {
    try {
      const { data } = await ApiService.instance.post(
        '/api/datasource/saveddesigns/deletedesign',
        { id },
      );
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateDesign = async (design, signal = null) => {
    try {
      const { data } = await ApiService.instance.post(
        '/api/datasource/saveddesigns/updatedesign',
        design,
        { signal },
      );
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static createDesign = async (designData) => {
    try {
      const {
        data: { data },
      } = await ApiService.instance.post('/api/savedesign', designData);
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getDesignThumbnailURL = async (body, signal = null) => {
    try {
      const { data } = await ApiService.instance.post(
        '/api/upload/uploadsaveddesign',
        body,
        { signal },
      );
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getSavedDesignCliparts = async (savedDesignID) => {
    try {
      const {
        data: { data },
      } = await ApiService.instance.get(
        `/api/datasource/saveddesigns/getcliparts?savedid=${savedDesignID}`,
      );
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getProjectsByUser = async (id) => {
    try {
      const { data } = await ApiService.instance.post(
        `/api/datasource/saveddesigns/getdesigns`,
        { id },
      );
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  };
}
