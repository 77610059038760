export const AiSettingsQuery = `
    #graphql
    query AiChatSettings {
      ai_chat {
        prompt
        model
        suggestions
      }
    }
`;
