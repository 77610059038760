const graphQLAPI = process.env.NEXT_PUBLIC_GRAPHQL;

const fetchData = async (query, { variables = {} }, additionalPath = '') => {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (process.env.DIRECTUS_API_TOKEN) {
    headers['Authorization'] = `Bearer ${process.env.DIRECTUS_API_TOKEN}`;
  }

  const res = await fetch(`${graphQLAPI}${additionalPath}`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await res.json();
  if (json.errors) {
    console.error(JSON.stringify(json.errors, null, 2));
  }
  return json;
};

export const getData = async (query, dataName, variables = {}) => {
  const data = await fetchData(query, {
    variables,
  });

  return data.data[dataName];
};

export default fetchData;
