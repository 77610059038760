export const CreateProduct = `
    #graphql
    mutation products($data: create_products_input!) {
        create_products_item(data: $data) {
          id
        }
    }
`;
export const SingleProductJsonByIdQuery = `
    #graphql
    query SingleProductById($product_id: ID!) {
      products_by_id(id: $product_id) {
        id
        title
        DesignJSON
        slug        
    		description
        price
    		category {
          Product_categories_cat_id {
            category_name
          }
        }
        product_type
        {
          id
        }
      }
    }
`;
