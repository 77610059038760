export const ShareProduct = `
    #graphql
    mutation ($data: create_shared_templates_input!) {
        create_shared_templates_item(data: $data) {
          id
        }
    }
`;

export const GetTemplate = `
    #graphql
    query GetTemplate ($designid: String!){
      shared_templates(filter: {id: {_eq: $designid} })
      {
        id
        template_name
        template_json
        product
        {
          id
          slug
          show_replace_image_popup
          description
        }
        saveddesign
        {
          id
          thumbnail
        }
        users
        {
          id
        }
      }
    }
`;

export const ProductIdBySharedTemplate = `
    #graphql
    query ProductIdBySharedTemplate ($designid: String!){
      shared_templates(filter: {id: {_eq: $designid} })
      {
        product
        {
          id
        }
      }
    }
`;
