export const GetEmailContent = `
    #graphql
    query GetEmailContent($slug: String)
      {
        email_template(filter:{slug:{_eq: $slug}}){
            content
            subject
            }
      }
`;

export const GetSmtpInfo = `
    #graphql
    query GetSmtpInfo($slug: String)
      {
        Sites(filter:{domain_name:{_eq: $slug}}){
            site_logo
            {
              id
            }
            id            
            site_url
            site_name
            from_email
            smtp_host
            smtp_port
            smtp_user
            smtp_password
            }
      }
`;
