export const GetLines = `
  #graphql
  query GetLines{
    lines(filter: {status: {_eq: "published"}}){
      id
      line_json
      line_title
      line_image{
        id
      }
    }
  }
`;

export const GetCategoryCount = `
  #graphql
  query GetCategoryCount($slug: String!){
    products(filter: {slug: {_eq: $slug}}){
      category{
        Product_categories_cat_id{
          category_name
          cat_id
          clipart_func{
            count
          }
        }
      }
    }
  }
`;

export const GetSources = `
  #graphql
  query GetSources{
    sources{
      id
      name
      url
    }
  }
`;
